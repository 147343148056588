.maincontainer {
  margin-right: 48px;
  margin-top: 48px;
  margin-bottom: 48px;
}

.cellBox {
  display: grid;
  grid-template-rows: repeat(4, 42px);
  grid-template-columns: repeat(4, 42px);
  gap: 16px;
  min-width: fit-content;
}

.channelUIContainer {
  flex-wrap: wrap;
  display: flex;
  max-width: 1000px;
  margin: auto;
}

.input {
  background: none;
  border: solid 2px #fffbee;
  color: #fffbee;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  font-family: "Meslo Bold";
}

.removeButton {
  align-self: baseline;
}

@media only screen and (max-width: 600px) {
  .container {
    margin-left: 16px;
  }
  .logo {
    margin-left: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .container {
    margin-left: 48px;
  }
  .logo {
    margin-left: 48px;
  }
}

.container p {
  font-size: 8px;
  line-height: 8px;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
}

.notfullscreen {
  position: relative;
}

.logoBR {
  position: absolute;
  right: 0;
  bottom: 0;
}

.logoBL {
  position: absolute;
  left: 0;
  bottom: 0;
}

.logoTL {
  position: absolute;
  left: 0;
  top: 0;
}

.logoTR {
  position: absolute;
  right: 0;
  top: 0;
}