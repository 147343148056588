.dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  pointer-events: none;
}

.description {
  z-index: 1000;
  opacity: 1;
  background-color: #fff;
  position: absolute;
  color: #000;
  pointer-events: auto;
  font-size: 10px;
}

.descriptionText {
  max-width: 300px;
}

.descriptionWelcome {
  font-size: 12px;
  max-width: 500px;
}

.svgOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.logoBR {
  position: absolute;
  right: 0;
  bottom: 0;
}

.logoBL {
  position: absolute;
  left: 0;
  bottom: 0;
}

.logoTL {
  position: absolute;
  left: 0;
  top: 0;
}

.logoTR {
  position: absolute;
  right: 0;
  top: 0;
}
