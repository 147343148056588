.mainContainer {
  flex-wrap: wrap;
  display: block;
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  padding: 16px;
}

.logoBR {
  position: absolute;
  right: 0;
  bottom: 0;
}

.logoBL {
  position: absolute;
  left: 0;
  bottom: 0;
}

.logoTL {
  position: absolute;
  left: 0;
  top: 0;
}

.logoTR {
  position: absolute;
  right: 0;
  top: 0;
}