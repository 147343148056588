/* Customize the label (the container) */
.container {
  display: grid;
  grid-template-rows: repeat(4, 42px);
  grid-template-columns: repeat(3, 42px);
  gap: 16px;
  align-content: start;
}

.channelUIContainer {
  width: fit-content;
  display: flex;
  padding: 16px;
}

.removeButton {
  align-self: baseline;
}