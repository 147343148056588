body {
  margin: 0;
  /* background-color: #202020; */
  /* background-color: rgba(0, 0, 0, 0.285); */
  /* color: white; */
  font-family: "Meslo Regular";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: Meslo Regular;
  src: url(/public/fonts/MesloLGS\ NF\ Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Meslo Bold;
  src: url(/public/fonts/MesloLGS\ NF\ Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1;
}

p {
  font-family: "Meslo Regular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Meslo Bold";
}

a {
  color: blue;
}
