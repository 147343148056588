.mainContainer {
  flex-wrap: wrap;
  display: block;
  width: 100vw;
  overflow: hidden;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #202020;
  color: white;
}
