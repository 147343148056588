.default {
  background: none;
  border: solid 2px #fffbee;
  color: #fffbee;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  font-family: "Meslo Bold";
}

.default:hover {
  background: #fffbee;
  color: #21243a;
  cursor: pointer;
}

.cell {
  width: 48px;
  height: 48px;
  background: none;
  border: solid 2px #fffbee;
  color: #fffbee;
  border-radius: 8px;
  font-family: "Meslo Regular";
}

.cell:hover {
  background: rgba(184, 173, 173, 0.8);
  color: #21243a;
  cursor: pointer;
}

.active {
  background: #4d4d4d;
  border: solid 2px #fffbee;
}

.isPlaying {
  border: solid 2px #e4540c;
}

button:disabled {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.05);
  border: solid 2px rgba(0, 0, 0, 0.05);
}

button:disabled:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.05);
}
